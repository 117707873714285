<template>
  <tr>
    <td class="justify-center">
      - - -
    </td>
    <td class="justify-left">
      {{ deviceEvent.id }}
    </td>
    <td class="justify-center">
      <div style="width: 100%; text-align: center;">
        <v-icon :color="deviceEvent.status_indi ? 'green' : 'red'">
          {{ deviceEvent.status_indi ? 'check' : 'keyboard_double_arrow_down' }}
        </v-icon>
        <br>
        <strong
          :style="{ color: deviceEvent.status_indi ? 'green' : 'red' }"
          v-html="deviceEvent.status_indi ? 'Resolvido' : 'Não resolvido'"
        ></strong>
      </div>
    </td>
    <td class="justify-left">
      <!-- <strong v-if="deviceEvent.desc_indi == 'Trigger'" style="display: flex; align-items: center">
        <v-icon>keyboard_double_arrow_down</v-icon>
        <span>Indisponibilidade</span>
      </strong> -->
      <strong>
        {{ deviceEvent.desc_indi }}
      </strong>
    </td>
    <td class="justify-left">
      <span>{{ deviceEvent.duration || '0' }}</span>
    </td>
    <td class="justify-left">
      <span>{{ deviceEvent.down_indi | moment('DD/MM/YYYY HH:mm:ss') }}</span>
    </td>
    <td class="justify-left">
      <span v-if="deviceEvent.up_indi">
        {{ deviceEvent.up_indi | moment('DD/MM/YYYY HH:mm:ss') }}
      </span>
      <span v-else>
        <strong>Até o momento</strong>
      </span>
    </td>
    <td class="justify-left">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-img
              width="25"
              :src="'static/icons/monitor/' + deviceEvent.type_info.icon + '.png'"
            ></v-img>
          </div>
        </template>
        <span>Evento criado a partir de um {{ deviceEvent.type_info.title }}.</span>
      </v-tooltip>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'DeviceEvents',
    data () {
      return {
        pingDialog: {
          active: false,
          host: null,
          server: null
        }
      }
    },
    computed: {
      deviceEvent () {
        return this.props.item
      }
    },
    methods: {
      pingDialogAction: function (active = false, server = null, host = null) {
        this.pingDialog.host = host
        this.pingDialog.server = server
        this.pingDialog.active = active
      },
      pingIsAvaiable: function (device) {
        return device.network_server.status && device.network_server.last_interaction_in_minutes < 5
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>